import { useContext } from 'react';
//
import { AuthContext } from './JwtContext';
// ----------------------------------------------------------------------
export var useAuthContext = function () {
    var context = useContext(AuthContext);
    if (!context)
        throw new Error('useAuthContext context must be use inside AuthProvider');
    return context;
};
