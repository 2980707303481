var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuthContext } from './useAuthContext';
export default function RoleBasedGuard(_a) {
    var hasContent = _a.hasContent, roles = _a.roles, children = _a.children;
    // Logic here to get current user role
    var user = useAuthContext().user;
    // const currentRole = 'user';
    var currentRole = user === null || user === void 0 ? void 0 : user.role; // admin;
    if (typeof roles !== 'undefined' && !(roles === null || roles === void 0 ? void 0 : roles.includes(currentRole))) {
        return hasContent ? (_jsxs(Container, __assign({ component: MotionContainer, sx: { textAlign: 'center' } }, { children: [_jsx(m.div, __assign({ variants: varBounce().in }, { children: _jsx(Typography, __assign({ variant: "h3", paragraph: true }, { children: "Permission Denied" })) })), _jsx(m.div, __assign({ variants: varBounce().in }, { children: _jsx(Typography, __assign({ sx: { color: 'text.secondary' } }, { children: "You do not have permission to access this page" })) })), _jsx(m.div, __assign({ variants: varBounce().in }, { children: _jsx(ForbiddenIllustration, { sx: { height: 260, my: { xs: 5, sm: 10 } } }) }))] }))) : null;
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
